<template>
  <ModuleContainer>
    <slot />
  </ModuleContainer>
</template>

<script>
import ModuleMixin from "@components/map-container/mixins/ModuleMixin";
import { ButtonGroupControl } from "@/components/map-container/control/ButtonGroupControl";

export default {
  mixins: [ModuleMixin],

  methods: {
    onInit() {
      this.addControl(
        ButtonGroupControl.create([
          {
            title: "Zoom In",
            icon: "mdi mdi-plus",
            onClick: (e) => {
              this.map.zoomIn(e);
            },
          },
          {
            title: "Zoom Out",
            icon: "mdi mdi-minus",
            onClick: (e) => {
              this.map.zoomOut(e);
            },
          },
        ])
      );
    },
  },
};
</script>
