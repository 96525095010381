import { ButtonControl } from "../../control/ButtonControl";

export class BaseMapButton extends ButtonControl {
  constructor({
    title = "",
    baseMaps = [],
    defaultIndexBaseMap = 0,
    onClick,
    className,
  } = {}) {
    super();
    this.title = title;
    this.baseMaps = baseMaps;
    this.currentIndex = defaultIndexBaseMap || 0;
    this.onClick = onClick;
    this.className = className;
  }
  onAdd(map) {
    this._map = map;
    // Button

    this._container = document.createElement("div");
    this._container.className = "mapboxgl-ctrl";
    const divContainer = document.createElement("div");
    divContainer.className = "d-flex";
    divContainer.style.height = "75px";
    const btn = this.createBtn({
      title: this.title,
      className: this.className,
      onclick: () => {
        if (this.onClick instanceof Function) {
          this.currentIndex = this.getNextIndex();
          this.onClick(this.baseMaps[this.currentIndex]);
          this.updateIconByBaseMap(this.baseMaps[this.getNextIndex()]);
        }
      },
    });
    this._btnMainContainer = btn;
    const list = this.createList();
    this._listMainContainer = list;

    this._container.onmouseover = () => {
      this._listMainContainer.style.display = "inline-block";
    };
    this._listMainContainer.style.display = "none";
    this._container.onmouseout = () => {
      this._listMainContainer.style.display = "none";
    };

    divContainer.appendChild(btn);
    divContainer.appendChild(list);

    this._container.appendChild(divContainer);
    this.updateIconByBaseMap(this.baseMaps[this.getNextIndex()]);

    return this._container;
  }
  createList() {
    const listContainer = document.createElement("div");
    this.baseMaps.forEach((baseMap, i) => {
      const btn = document.createElement("div");
      btn.title = baseMap.title;
      btn.className = "text-center d-inline-block basemap-item";
      btn.ariaLabel = baseMap.title;
      btn.ariaPressed = false;
      btn.style.height = "100%";
      btn.style.border = "unset";
      btn.style.lineHeight = "0";
      btn.setAttribute("data-index", i);
      btn.onclick = () => {
        if (this.onClick instanceof Function) {
          if (this.currentIndex == i) return;
          this.currentIndex = i;
          this.onClick(baseMap);
          this.updateIconByBaseMap(this.getNextBaseMap());
        }
      };
      const div = document.createElement("div");
      div.className = "basemap-image";
      const icon = document.createElement("img");
      icon.src = baseMap.thumbnail;
      icon.height = "48";
      icon.width = "48";
      icon.style["border-radius"] = "8px";
      icon.ariaHidden = true;
      div.appendChild(icon);
      btn.appendChild(div);
      const label = document.createElement("label");
      label.innerHTML = baseMap.title;
      label.className = "basemap-label";
      btn.appendChild(label);
      listContainer.appendChild(btn);
    });
    listContainer.className = "basemap-list-container";
    return listContainer;
  }
  createBtn(option = {}, baseMap = {}) {
    const btn = document.createElement("div");
    btn.className = option.className || "mapboxgl-ctrl-basemap-control";
    btn.title = option.title;
    btn.ariaLabel = option.title;
    btn.ariaPressed = false;

    btn.style.height = "75px";
    btn.style.width = "75px";
    btn.onclick = () => {
      if (option.onclick instanceof Function) {
        option.onclick();
      }
    };

    const icon = document.createElement("img");
    icon.src = baseMap.thumbnail;
    icon.style.height = "100%";
    icon.style["border-radius"] = "8px";
    icon.style.width = "100%";
    icon.ariaHidden = true;

    btn.appendChild(icon);
    const containerName = document.createElement("div");
    containerName.className = "basemap-control-label-container";
    const iconBasemap = document.createElement("i");
    iconBasemap.className =
      "basemap-control-icon mdi mdi-layers-outline d-block";
    containerName.appendChild(iconBasemap);
    const labelBasemap = document.createElement("p");
    labelBasemap.className = "basemap-control-label";
    labelBasemap.innerHTML = "Lớp bản đồ";
    containerName.appendChild(labelBasemap);

    btn.appendChild(containerName);
    return btn;
  }
  updateIconByBaseMap(baseMap) {
    this._btnMainContainer.childNodes[0].src = baseMap.thumbnail;
    this._listMainContainer.childNodes.forEach((child) => {
      let indexBaseMap = child.getAttribute("data-index");
      if (indexBaseMap == this.currentIndex) {
        child.classList.add("basemap-item-active");
      } else {
        child.classList.remove("basemap-item-active");
      }
    });
  }
  getNextBaseMap() {
    let index = this.currentIndex;
    index++;
    if (index > this.baseMaps.length - 1) {
      index = 0;
    }
    return this.baseMaps[index];
  }
  getNextIndex() {
    let index = this.currentIndex;
    index++;
    if (index > this.baseMaps.length - 1) {
      index = 0;
    }
    return index;
  }
}
