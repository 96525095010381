<template>
  <ModuleContainer>
    <slot />
  </ModuleContainer>
</template>

<script>
import ModuleMixin from "../../mixins/ModuleMixin";
import { ButtonGroupControl } from "@/components/map-container/control/ButtonGroupControl";

export default {
  mixins: [ModuleMixin],
  data: () => ({
    eventBind: undefined,
  }),

  methods: {
    onInit() {
      this.addControl(
        ButtonGroupControl.create([
          {
            title: "Fullscreen",
            icon: "mdi mdi-fullscreen",
            onClick: () => {
              if (document.fullscreenElement) {
                this.closeFullscreen();
              } else {
                this.openFullscreen(this.getMapContainer());
              }
            },
          },
        ])
      );
    },
    getMapContainer(el = this.map.getContainer()) {
      if (el.classList.contains("map-container") || el.tagName === "BODY") {
        return el;
      } else {
        return this.getMapContainer(el.parentElement);
      }
    },

    /* View in fullscreen */
    openFullscreen(elem) {
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.webkitRequestFullscreen) {
        /* Safari */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        /* IE11 */
        elem.msRequestFullscreen();
      }
    },

    /* Close fullscreen */
    closeFullscreen() {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        /* Safari */
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        /* IE11 */
        document.msExitFullscreen();
      }
    },
  },
};
</script>
