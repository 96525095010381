<template>
  <div class="mapboxgl-ctrl mapboxgl-ctrl-group">
    <button
      class="mapboxgl-ctrl-geolocate"
      type="button"
      title="Tìm vị trí của tôi"
      aria-label="Tìm vị trí của tôi"
      aria-pressed="false"
      @click="onClick()"
      v-if="!disable"
      style="font-size: 18px"
      :class="{ 'primary--text': active }"
    >
      <i class="mdi mdi-crosshairs-gps" aria-hidden="true"></i>
    </button>
    <button
      v-else
      class="mapboxgl-ctrl-geolocate"
      type="button"
      disabled=""
      title="Định vị không khả dụng"
      aria-label="Định vị không khả dụng"
      aria-pressed="false"
      style="font-size: 18px"
    >
      <I class="mdi mdi-crosshairs-off" aria-hidden="true"></I>
    </button>
  </div>
</template>

<script>
import { getLocation } from "@/utils";
import { Marker } from "mapbox-gl";
import { API_GET_LOCATION } from "@/service/api";
export default {
  data: () => ({
    map: null,
    _dotElement: null,
    _circleElement: null,
    _userLocationDotMarker: null,
    bindedOnZoomLayer: null,
    center: [],
    disable: false,
    active: false,
    isZoomTo: false,
  }),
  methods: {
    async onClick() {
      this.disable = false;
      if (this.center && this.center.length > 0)
        this.map.flyTo({
          center: this.center,
          zoom: 15,
          duration: 0,
        });
      if (!this.isZoomTo && this.active) {
        this.isZoomTo = true;
        return;
      }
      if (!this.active) {
        await this.onGetLocation();
      } else {
        this.active = false;
        this.onDestroy();
      }
    },
    onInit(map) {
      this.map = map;
      this.bindedOnZoomLayer = this.onZoom.bind(this);
    },
    onDestroy() {
      this.map.off("zoom", this.bindedOnZoomLayer);
      if (this._userLocationDotMarker) {
        this._userLocationDotMarker.remove();
      }
      if (this._accuracyCircleMarker) {
        this._accuracyCircleMarker.remove();
      }
    },
    onGetLocation() {
      getLocation()
        .then((location) => {
          if (!location) {
            return;
          }
          this.active = true;
          this.location = {
            lat: location.latitude,
            lng: location.longitude,
          };
          this.center = [this.location.lng, this.location.lat];
          this.onAddUi();
          this.map.on("zoom", this.bindedOnZoomLayer);
          this.$emit("update:location", this.location);
          API_GET_LOCATION(this.location.lat, this.location.lng).then((res) => {
            this.$emit("update:region", res);
          });
        })
        .catch((e) => {
          this.disable = true;
          console.error(e);
        });
    },
    onAddUi() {
      this._dotElement = DOMcreate("div", "mapboxgl-user-location");

      this._dotElement.classList.add("mapboxgl-user-location");
      this._dotElement.appendChild(
        DOMcreate("div", "mapboxgl-user-location-dot")
      );
      this._dotElement.appendChild(
        DOMcreate("div", "mapboxgl-user-location-heading")
      );
      this._userLocationDotMarker = new Marker({
        element: this._dotElement,
        rotationAlignment: "map",
        pitchAlignment: "map",
      });

      this._circleElement = DOMcreate(
        "div",
        "mapboxgl-user-location-accuracy-circle"
      );
      this._accuracyCircleMarker = new Marker({
        element: this._circleElement,
        pitchAlignment: "map",
      });
      this._userLocationDotMarker.setLngLat(this.center).addTo(this.map);
      this._accuracyCircleMarker.setLngLat(this.center).addTo(this.map);
      this.updateCircleRadius();
    },
    updateCircleRadius() {
      const y = this.map._container.clientHeight / 2;
      const a = this.map.unproject([0, y]);
      const b = this.map.unproject([100, y]);
      const metersPerPixel = a.distanceTo(b) / 100;
      const KM10 = 10000;
      const circleDiameter = Math.ceil(KM10 / metersPerPixel);
      this._circleElement.style.width = `${circleDiameter}px`;
      this._circleElement.style.height = `${circleDiameter}px`;
    },
    onZoom() {
      this.updateCircleRadius();
    },
  },
};
const DOMcreate = function (tagName, className, container) {
  const el = window.document.createElement(tagName);
  if (className !== undefined) el.className = className;
  if (container) container.appendChild(el);
  return el;
};
</script>

<style></style>
