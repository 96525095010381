<template>
  <ModuleContainer>
    <slot />
  </ModuleContainer>
</template>

<script>
import ModuleMixin from "@components/map-container/mixins/ModuleMixin";
import { CustomGeolocaleButton } from "./CustomGeolocaleButton.js";
import MapboxMapGeolocaleButton from "./MapboxMapGeolocaleButton.vue";
import LeafletMapGeolocaleButton from "./LeafletMapGeolocaleButton.vue";
import Vue from "vue";
export default {
  mixins: [ModuleMixin],
  data: () => ({
    location: {},
  }),
  methods: {
    onInit() {
      this.onAddControl();
    },
    onDestroy() {},
    onAddControl() {
      let vue = this.isMapbox
        ? new Vue(MapboxMapGeolocaleButton)
        : new Vue(LeafletMapGeolocaleButton);
      vue.$mount();
      vue.$on("update:region", (e) => {
        this.$emit("update:region", e);
      });
      vue.$on("update:location", (e) => {
        this.$emit("update:location", e);
      });
      this.addControl(new CustomGeolocaleButton(vue));
    },
    onGetLocation() {
      if (this.control) {
        this.control.onClick();
      }
    },
  },
};
</script>
