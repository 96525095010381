<template>
  <span :class="{ 'error--text': !value, 'success--text': value }">
    {{ status }}
  </span>
</template>

<script>
export default {
  props: {
    value: { type: Boolean },
  },
  data: () => ({ current: {} }),
  watch: {},
  computed: {
    status() {
      return this.value ? "Mở cửa" : "Đóng cửa";
    },
  },
};
</script>

<style></style>
