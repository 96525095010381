<template>
  <div class="mapboxgl-ctrl mapboxgl-ctrl-group">
    <button
      class="mapboxgl-ctrl-geolocate"
      type="button"
      title="Tìm vị trí của tôi"
      aria-label="Tìm vị trí của tôi"
      aria-pressed="false"
      @click="onClick()"
      v-if="!disable"
      style="font-size: 18px"
      :class="{ 'primary--text': active }"
    >
      <i class="mdi mdi-crosshairs-gps" aria-hidden="true"></i>
    </button>
    <button
      v-else
      class="mapboxgl-ctrl-geolocate"
      type="button"
      disabled=""
      title="Định vị không khả dụng"
      aria-label="Định vị không khả dụng"
      aria-pressed="false"
      style="font-size: 18px"
    >
      <I class="mdi mdi-crosshairs-off" aria-hidden="true"></I>
    </button>
  </div>
</template>

<script>
import { getLocation } from "@/utils";
import L from "leaflet";
import { API_GET_LOCATION } from "@/service/api";
export default {
  data: () => ({
    center: [],
    disable: false,
    active: false,
  }),
  methods: {
    async onClick() {
      this.disable = false;
      if (!this.active) {
        await this.onGetLocation();
      } else {
        this.active = false;
        this.onDestroy();
      }
    },
    onInit(map) {
      this.map = map;
    },
    onGetLocation() {
      getLocation()
        .then((location) => {
          if (!location) {
            return;
          }
          this.active = true;
          this.location = {
            lat: location.latitude,
            lng: location.longitude,
          };
          this.center = [this.location.lng, this.location.lat];

          this.map.flyTo(L.latLng(this.center[1], this.center[0]));
          this.onAddUi();
          this.$emit("update:location", this.location);
          API_GET_LOCATION(this.location.lat, this.location.lng).then((res) => {
            this.$emit("update:region", res);
          });
        })
        .catch((e) => {
          this.disable = true;
          console.error(e);
        });
    },
    onDestroy() {
      if (this.point) {
        this.point.remove();
      }
      if (this.circle) {
        this.circle.remove();
      }
    },
    onAddUi() {
      this.point = L.marker(this.location, {
        icon: L.icon({
          iconSize: [25, 42],
          iconAnchor: [12, 21],
          iconUrl: "/images/leaflet/marker-icon.png",
          shadowUrl: "/images/leaflet/marker-shadow.png",
        }),
      }).addTo(this.map);
      this.circle = L.circle(this.location, 10000).addTo(this.map);
    },
  },
};
</script>

<style></style>
